import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    sizeWizList: [],


  };


  const slice = createSlice({
    name: 'sizeWiz',
    initialState,
    reducers: {
      startLoading(state) {
        state.isLoading = true;
      },
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
      getSizeWizSuccess(state, action) {
        console.log('Reducer payload:', action.payload); // Log the payload
        state.isLoading = false;
        state.sizeWizList = action.payload;
      },
      updateSizeWizSuccess(state, action) {
        state.isLoading = false;
        const updatedData = action.payload;
        // state.sizeWizList = state.sizeWizList.map(item =>
        //   item.id === updatedData.id ? updatedData : item
        // );
      },
    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------
  

  export function getSizeWiz() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/sizeV');
        // console.log('Fetched data:', response.data); // Log the fetched data
        dispatch(slice.actions.getSizeWizSuccess(response.data));
        // console.log(response.data,'response');
      } catch (error) {
        // console.error('Fetch error:', error.response ? error.response.data : error.message); // Log the error
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function updateSizeWiz(id,newSizeWiz) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        // console.log("update",newSizeWiz);
        const response = await axios.put(`/api/sizeV/update/${id}`, newSizeWiz);
        dispatch(slice.actions.updateSizeWizSuccess(response.data.event));
        // console.log(response);
        return { payload: {} };
      } catch (error) {
        console.log(error.message)
        dispatch(slice.actions.hasError(error));
        return { payload: { error: error.message } };
      }
    };
  }