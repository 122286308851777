import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    collectionList: [],


  };


  const slice = createSlice({
    name: 'collection',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
    
      getCollectionSuccess(state, action) {
        state.isLoading = false;
        state.collectionList = action.payload;
      },
  

      addCollectionSuccess(state, action) {
        const newCollection = action.payload; 
        state.isLoading = false;
      },

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------
  

  export function getCollection() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/productCollection');
        dispatch(slice.actions.getCollectionSuccess(response.data));
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function addCollection(newCollection) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("new",newCollection);
        const response = await axios.post('/api/productCollection/add', newCollection);
        dispatch(slice.actions.addCollectionSuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function updateCollection(id,newCollection) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("update",newCollection);
        const response = await axios.put(`/api/productCollection/update/${id}`, newCollection);
        dispatch(slice.actions.addCollectionSuccess(response.data.event));
        // console.log(response);
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  // export function deleteFabricCompatibilityProfile(id) {
  //   return async (dispatch) => {
  //     dispatch(slice.actions.startLoading());
  //     try {
  //       console.log("delete");
  //       const response = await axios.delete(`/api/fabricComPro/delete/${id}`);
  //       console.log(response);
  //       dispatch(slice.actions.addFabricCompatibilityProfile(response.data.event));
  //       return { payload: {} };
  //     } catch (error) {
  //       console.log('error')
  //       dispatch(slice.actions.hasError(error));
  //       return { payload: { error: 'Error' } };
  //     }
  //   };
  // }



  // export function updateUser(ID, updateUser) {
  //   return async () => {
  //     dispatch(slice.actions.startLoading());
  //     try {
  //       const response = await axios.post('/api/user/update', { ...updateUser, ID});
  //        console.log(response,'userresponse')
  //       dispatch(slice.actions.updateUserSuccess(response.data.event));
  //       return { payload: {} };
  //     } catch (error) {
  //       if (error.message === 'Email already in use') {
  //         // Handle the specific error
  //         dispatch(slice.actions.hasError(error));
  //         console.log('Email already in use');
  //         return { payload: { error: 'Email already in use' } };
  //       } 
  //         // Handle other errors
  //         dispatch(slice.actions.hasError(error));
  //         console.log('Generic error');
  //         return { payload: { error: 'Email already in use' } };
        
  //     }
      
  //   };
  // }



  


 
  