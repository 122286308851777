import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    header: {},
  };


  const slice = createSlice({
    name: 'headerConfig',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
      getHeaderSuccess(state, action) {
        state.isLoading = false;
        state.header = action.payload;
      },

      addHeaderSuccess(state,action){
        state.isLoading = false;
      }
    },
  });
  
  // Reducer
  export default slice.reducer;
  
  export function getHeaderConfig() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/headerConfig');
        dispatch(slice.actions.getHeaderSuccess(response.data[0]));
        console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function addHeaderConfig(data) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/api/headerConfig/add', data);
        dispatch(slice.actions.addHeaderSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log(error)
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function updateHeaderConfig(id,data) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/api/headerConfig/update/${id}`, data);
        dispatch(slice.actions.addHeaderSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log(error)
        dispatch(slice.actions.hasError(error));
        return { payload: { error: error.message } };
      }
    };
  }


  


 
  