import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    fabricPropertyNames: {},
    fabricPropertyCounts: {},
    fabricPropertyList: [],
  };


  const slice = createSlice({
    name: 'fabricProperties',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },

      getPropertyNameSuccess(state, action) {
        state.isLoading = false;
        state.fabricPropertyNames = action.payload;
      },
      
      getPropertyCountsSuccess(state, action) {
        state.isLoading = false;
        state.fabricPropertyCounts = action.payload;
      },
    
      getFabricPropertySuccess(state, action) {
        state.isLoading = false;
        state.fabricPropertyList = action.payload;
      },
  
      addFabricSuccess(state, action) {
        const newFabric = action.payload; 
        state.isLoading = false;
      },

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------

  export function addFabricProperty(data){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/api/fabricVProp/add',data);
        dispatch(slice.actions.addFabricSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        return { payload: { error: error.message } };
      }
    };
  }

  export function updateFabricProperty(id,data){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/api/fabricVProp/update/${id}`,data);
        dispatch(slice.actions.addFabricSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        return { payload: { error: error.message } };
      }
    };
  }

  export function deleteFabricProperty(id){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/api/fabricVProp/delete/${id}`);
        dispatch(slice.actions.addFabricSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
  export function getFabricProperty() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/fabricVProp/');
        dispatch(slice.actions.getFabricPropertySuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  export function getFabricPropertyNames() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/fabricVProp/getFabricPropNames/');
        dispatch(slice.actions.getPropertyNameSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  export function getFabricPropertyCounts() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/fabricVProp/getPropCounts/');
        dispatch(slice.actions.getPropertyCountsSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  // export function addFabric(newFabric) {
  //   return async (dispatch) => {
  //     dispatch(slice.actions.startLoading());
  //     try {
  //       const response = await axios.post('/api/fabric/', newFabric);
  //       dispatch(slice.actions.addFabricSuccess(response.data.event));
  //       return { payload: {} };
  //     } catch (error) {
  //       console.log('error')
  //       dispatch(slice.actions.hasError(error));
  //       return { payload: { error: 'Error' } };
  //     }
  //   };
  // }
  



  


 
  