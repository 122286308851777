import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { displayName } from 'react-quill';
import axios from '../utils/axios';
import { isValidToken, setSession, setUser } from '../utils/jwt';


// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          // const response = await axios.get('/api/account/my-account');
          // const { user } = response.data;
          const user = JSON.parse(window.localStorage.getItem('user'));

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: {
                id: user.id,
                displayName: user.displayName,
                email: user.email,
                phoneNumber:user.phoneNumber,
                role: user.role,
                profileURL:user.profileURL
              },
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, [initialState]);

  // const login = async (email, password) => {
  //   const response = await axios.post('/api/account/login', {
  //     email,
  //     password,
  //   });
  //   const { accessToken, user } = response.data;

  //   setSession(accessToken);
  //   dispatch({
  //     type: 'LOGIN',
  //     payload: {
  //       user,
  //     },
  //   });
  // };


  const login = async (email, password) => {
    try {
      const response = await axios.post('/api/admin/login', {
        email,
        password,
      });
  
      const { token, _id, name, email: userEmail } = response.data;
      console.log(response.data.name,'data')

      if(!token){
        return {
          success: false,
          error: 'Login failed. Please try again.',
        };
      }


      const user = {
        id: response.data._id,
        displayName: response.data.name,
        email: response.data.email,
        phoneNumber:response.data.phone,
        role: response.data.role,
        profileURL:response.data.image
      };

      // Assuming you have a setSession function to handle token storage
      setSession(token);
      setUser(JSON.stringify(user));
  
      // Dispatch the login action with the updated user object
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: response.data._id,
            displayName: response.data.name,
            email: response.data.email,
            phoneNumber:response.data.phone,
            role: response.data.role,
            profileURL:response.data.image
          },
        },
      });
  
      // Optionally, you can return the user object or any other relevant data
      // return {
      //   success: true,
      //   user: {
      //     id: _id,
      //     FullName: name,
      //     email: userEmail,
      //     // Add other fields as needed from your API response
      //   },
      // };
    } catch (error) {
      // Handle login failure, you might want to dispatch an error action
      console.error('Login failed:', error);
      return {
        success: false,
        error: 'Login failed. Please try again.',
      };
    }
  };
  
  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
