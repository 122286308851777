import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    users: [],
    roles: [],
    permissionsData: []
  };


  const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
    
      getUsersSuccess(state, action) {
        state.isLoading = false;
        state.users = action.payload;
      },
    
      getRolesSuccess(state, action) {
        state.isLoading = false;
        state.roles = action.payload;
      },

      addUserSuccess(state, action) {
        const newUser = action.payload; 
        state.isLoading = false;
      },

      getPermissionSuccess(state,action){
        state.isLoading = false;
        state.permissionsData = action.payload;
      }

    },
  });

  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------
  

  // export function getUsers(page,limit) {
  //   return async () => {
  //     dispatch(slice.actions.startLoading());
  //     try {
  //       const response = await axios.get('/api/user');
  //       dispatch(slice.actions.getUsersSuccess(response.data));
  //       // console.log(response.data,'response');
  //     } catch (error) {
  //       dispatch(slice.actions.hasError(error));
  //       console.log(error);
  //     }
  //   };
  // }
  export function getUsers(page, limit) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        let url = '/api/user';
        const params = new URLSearchParams();
  
        if (page !== undefined) {
          params.append('page', page);
        }
        if (limit !== undefined) {
          params.append('limit', limit);
        }
  
        if (params.toString()) {
          url += `?${params.toString()}`;
        }
  
        const response = await axios.get(url);
        dispatch(slice.actions.getUsersSuccess(response.data));
        // console.log(response.data, 'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  }

  export function getAllAdmin(){
    return async (dispatch) => {
      try{
        const response = await axios.get('/api/admin/getAllAdmin');
        dispatch(slice.actions.getUsersSuccess(response.data));
      }
      catch(error){
        dispatch(slice.actions.hasError(error));
      }
    }
  }

  export function getAllAdminRoles(){
    return async (dispatch) => {
      try{
        const response = await axios.get('/api/admin/role');
        dispatch(slice.actions.getRolesSuccess(response.data));
      }
      catch(error){
        dispatch(slice.actions.hasError(error));
      }
    }
  }

  export function addRole(data){
    return async (dispatch) => {
      try{
        const response = await axios.post('/api/admin/role',data);
        dispatch(slice.actions.addUserSuccess(response.data));
      }
      catch(error){
        dispatch(slice.actions.hasError(error));
      }
    }
  }

  export function updateRole(id,data){
    return async (dispatch) => {
      try{
        const response = await axios.put(`/api/admin/role/${id}`,data);
        dispatch(slice.actions.addUserSuccess(response.data));
      }
      catch(error){
        dispatch(slice.actions.hasError(error));
      }
    }
  }

  export function addAdminUser(data){
    return async (dispatch) => {
      try{
        const response = await axios.post('/api/admin/new-user',data);
        dispatch(slice.actions.addUserSuccess(response.data));
      }
      catch(error){
        dispatch(slice.actions.hasError(error));
      }
    }
  }
  export function updateAdminUser(id,data){
    return async (dispatch) => {
      try{
        const response = await axios.put(`/api/admin/userUpdate/${id}`,data);
        dispatch(slice.actions.addUserSuccess(response.data));
      }
      catch(error){
        dispatch(slice.actions.hasError(error));
      }
    }
  }

  export function getAllForms(){
    return async (dispatch) => {
      try{
        const response = await axios.get('/api/admin/form');
        dispatch(slice.actions.getPermissionSuccess(response.data));
      }
      catch(error){
        dispatch(slice.actions.hasError(error));
      }
    }
  }

  


  // export function updateUser(ID, updateUser) {
  //   return async () => {
  //     dispatch(slice.actions.startLoading());
  //     try {
  //       const response = await axios.post('/api/user/update', { ...updateUser, ID});
  //        console.log(response,'userresponse')
  //       dispatch(slice.actions.updateUserSuccess(response.data.event));
  //       return { payload: {} };
  //     } catch (error) {
  //       if (error.message === 'Email already in use') {
  //         // Handle the specific error
  //         dispatch(slice.actions.hasError(error));
  //         console.log('Email already in use');
  //         return { payload: { error: 'Email already in use' } };
  //       } 
  //         // Handle other errors
  //         dispatch(slice.actions.hasError(error));
  //         console.log('Generic error');
  //         return { payload: { error: 'Email already in use' } };
        
  //     }
      
  //   };
  // }
  



  


 
  