import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, Button, List, ListItem, ListItemText, Grid, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';

const RHFUploadFolder = ({ name }) => {
  const { control } = useFormContext();

  // return (
  //   <Controller
  //     name={name}
  //     control={control}
  //     render={({ field: { onChange, value }, fieldState: { error } }) => (
  //       <>
  //         <DropzoneArea onChange={onChange} />
  //         {error && <p>{error.message}</p>}
  //         <List>
  //           {value && value.map((file, index) => (
  //             <ListItem key={file?.imageCode+index}>
  //               <ListItemText primary={file.imageCode} />
  //             </ListItem>
  //           ))}
  //         </List>
  //       </>
  //     )}
  //   />
  // );
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <DropzoneArea onChange={onChange} />
          {error && <p>{error.message}</p>}
          <Grid container spacing={2}>
            {value && value.map((file, index) => (
              <Grid item xs={6} sm={4} md={3} key={file?.imageCode + index}>
                <div style={{ textAlign: 'center' }}>
                  <img src={file?.imageStream} alt={file?.imageCode} style={{ width: '100%', height: 'auto' }} />
                  <Typography variant="h6">{file.imageCode}</Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    />
  );
};

const DropzoneArea = ({ onChange }) => {
  const [files, setFiles] = useState([]);

  const onDrop = async (acceptedFiles) => {
    const filePromises = acceptedFiles.map(file => 
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve({
            imageCode: file.name,
            imageStream: reader.result
          });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      })
    );

    const fileData = await Promise.all(filePromises);
    setFiles(fileData);
    onChange(fileData);
  };

  const handleFolderSelection = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    const filePromises = selectedFiles.map(file => 
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve({
            imageCode: file.name,
            imageStream: reader.result
          });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      })
    );

    const fileData = await Promise.all(filePromises);
    setFiles(fileData);
    onChange(fileData);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    webkitdirectory: true,
    directory: true
  });

  return (
    <Box {...getRootProps()} border="2px dashed #ccc" p={2} textAlign="center">
      <input type="file"
        webkitdirectory="true"
        directory="true"
        style={{ display: 'none' }}
        onChange={handleFolderSelection}
        id="folderInput"/>
      <p>Drag & drop some files here, or click to select files</p>
      <Button variant="contained" onClick={() => document.getElementById('folderInput').click()}>
        Select Folder
      </Button>
    </Box>
  );
};

export default RHFUploadFolder;
