import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
  const initialState = {
    isLoading: false,
    error: null,
    fabricList: [],
    fabricById: {},
  };

  const slice = createSlice({
    name: 'fabric',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
      getFabricByIdSuccess(state,action){
        state.isLoading = false;
        state.fabricById = action.payload;
      },
    
      getFabricSuccess2(state, action) {
        state.isLoading = false;
        state.fabricList = action.payload;
      },
  
      addFabricSuccess(state, action) {
        const newFabric = action.payload; 
        state.isLoading = false;
      },

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------
  

  export function getFabric() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/fabricV/');
        console.log("fabric response",response.data);
        dispatch(slice.actions.getFabricSuccess2(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  };

  export function getFabricById(id) {
    if(id !== null){
      return async () => {
        dispatch(slice.actions.startLoading());
        try {
          const response = await axios.get(`/api/fabricV/${id}`);
          console.log("fabric response",response.data);
          dispatch(slice.actions.getFabricByIdSuccess(response.data));
        } catch (error) {
          dispatch(slice.actions.hasError(error));
        }
      };
    }
  };

  export function addFabric(newFabric) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/api/fabricV/add', newFabric);
        dispatch(slice.actions.addFabricSuccess(response.data.event));
        console.log(response);
        return { payload: {} };
      } catch (error) {
        console.log(error)
        dispatch(slice.actions.hasError(error));
        return { payload: { error: error.message } };
      }
    };
  }

  export function updateFabric(id,newFabric) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/api/fabricV/update/${id}`, newFabric,{
          headers: {
            'Content-Type': 'application/json'
          }
        });
        dispatch(slice.actions.addFabricSuccess(response.data.event));
        console.log(response);
        return { payload: {} };
      } catch (error) {
        console.log("error",error)
        dispatch(slice.actions.hasError(error));
        return { payload: { error: error.message } };
      }
    };
  }
  



  


 
  