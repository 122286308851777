import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    attributePresetList: [],
    attributeSubPreset: [],
    isModalOpen: false,
  };

  const slice = createSlice({
    name: 'attributePreset',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
    
      getAttributeSuccess(state, action) {
        state.isLoading = false;
        state.attributePresetList = action.payload;
      },  
      
      getSubPresetSuccess(state, action) {
        state.isLoading = false;
        state.attributeSubPreset = action.payload;
      },  

      addAttributeSuccess(state, action) {
        const newAttribute = action.payload; 
        state.isLoading = false;
      },

      openModal(state) {
        state.isModalOpen = true;
      },
  
      // CLOSE MODAL
      closeModal(state) {
        state.isModalOpen = false;
      },
    },
  });
  
  // Reducer
  export default slice.reducer;

  export function addAttributePreset(newAttributeType) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("new",newAttributeType);
        const response = await axios.post('/api/stylePreset/add', newAttributeType);
        dispatch(slice.actions.addAttributeSuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: error.message } };
      }
    };
  }

  export function editAttributePreset(id,newAttributeType) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("new",newAttributeType);
        const response = await axios.put(`/api/stylePreset/update/${id}`, newAttributeType);
        dispatch(slice.actions.addAttributeSuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: error.message } };
      }
    };
  }

  export function deleteAttributePreset(id){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/api/stylePreset/delete/${id}`);
        dispatch(slice.actions.deleteAttributePreset(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function getAttributePreset() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/stylePreset/false');

        dispatch(slice.actions.getAttributeSuccess(response.data));
        console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function getAttributeSubPreset() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        // const response = await axios.get('/api/fabric');
        const response = await axios.get('/api/stylePreset/true');

        dispatch(slice.actions.getSubPresetSuccess(response.data));
        console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  // Actions
  export const {openModal,closeModal} = slice.actions;

  
  // ----------------------------------------------------------------------
  



  


 
  