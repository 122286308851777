import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    fabricCategoryList: [],
    isModalOpen: false,
  };

  const slice = createSlice({
    name: 'fabricCategory',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
    
      getFabricCategorySuccess(state, action) {
        state.isLoading = false;
        state.fabricCategoryList = action.payload;
      },

      addFabricCategorySuccess(state, action) {
        const fabricCategory = action.payload; 
        state.isLoading = false;
      },

      openModal(state) {
        state.isModalOpen = true;
      },
  
      // CLOSE MODAL
      closeModal(state) {
        state.isModalOpen = false;
      },

    },
  });
  
  // Reducer
  export default slice.reducer;
  
  export function getFabricCategory() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/fabricCategory');
        dispatch(slice.actions.getFabricCategorySuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function addFabricCategory(fabricCategory) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("new",fabricCategory);
        const response = await axios.post('/api/fabricCategory/add', fabricCategory);
        dispatch(slice.actions.addFabricCategorySuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function updateFabricCategory(id,fabricCategory) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("update",fabricCategory);
        const response = await axios.put(`/api/fabricCategory/update/${id}`, fabricCategory);
        dispatch(slice.actions.addFabricCategorySuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function deleteFabricCategory(id) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("delete");
        const response = await axios.put(`/api/fabricCategory/delete/${id}`);
        console.log(response);
        dispatch(slice.actions.addAttributeDetail(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  // Actions
  export const {openModal,closeModal} = slice.actions;

  
  // ----------------------------------------------------------------------
  



  


 
  