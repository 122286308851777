import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  productGroupingList: [],
};

const slice = createSlice({
  name: 'productGrouping',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductGroupingSuccess(state, action) {
      state.isLoading = false;
      state.productGroupingList = action.payload;
    },

    addProductGroupingSuccess(state, action) {
      const newProduct = action.payload; 
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProductGrouping() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/productGroupV/');
      dispatch(slice.actions.getProductGroupingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addProductGrouping(newProduct) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/productGroupV/add', newProduct);
      dispatch(slice.actions.addProductGroupingSuccess(response.data.event));
      return { payload: {} };
    } catch (error) {
      console.log('error')
      dispatch(slice.actions.hasError(error));
      return { payload: { error: 'Error' } };
    }
  };
}

export function updateProductGrouping(id,data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/productGroupV/update/${id}`, data);
      dispatch(slice.actions.addProductGroupingSuccess(response.data.event));
      return { payload: {} };
    } catch (error) {
      console.log('error')
      dispatch(slice.actions.hasError(error));
      return { payload: { error: 'Error' } };
    }
  };
}

