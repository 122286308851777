import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    categories: [],
    subCategories: [],
  };


  const slice = createSlice({
    name: 'category',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
    
      getCategorySuccess(state, action) {
        state.isLoading = false;
        state.categories = action.payload;
      },

      getSubCategorySuccess(state, action) {
        state.isLoading = false;
        state.subCategories = action.payload;
      },

      addCategorySuccess(state, action) {
        const newCategory = action.payload; 
        state.isLoading = false;
      },

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------
  

  export function getCategory() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/category');
        dispatch(slice.actions.getCategorySuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function addCategory(newCategory) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("new",newCategory);
        const response = await axios.post('/api/category/add', newCategory);
        dispatch(slice.actions.addCategorySuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: error.message } };
      }
    };
  }

  export function updateCategory(id,newCategory) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("update",newCategory);
        const response = await axios.put(`/api/category/update/${id}`, newCategory);
        dispatch(slice.actions.addCategorySuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: error.message } };
      }
    };
  }

  export function getSubCategory() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/subCategory');
        dispatch(slice.actions.getSubCategorySuccess(response.data));
        // console.log(response.data,'response')
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        return { payload: { error: error.message } };
      }
    };
  }

  export function addSubCategory(newSubCategory) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/api/subCategory/add', newSubCategory);
        dispatch(slice.actions.addCategorySuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: error.message } };
      }
    };
  }

  export function updateSubCategory(id,newSubCategory) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/api/subCategory/update/${id}`, newSubCategory);
        dispatch(slice.actions.addCategorySuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: error.message } };
      }
    };
  }

  export function deleteSubCategory(id) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/api/subCategory/delete/${id}`);
        dispatch(slice.actions.addCategorySuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
      }
    };
  }




  export function updateUser(ID, updateUser) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/api/user/update', { ...updateUser, ID});
         console.log(response,'userresponse')
        dispatch(slice.actions.updateUserSuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        if (error.message === 'Email already in use') {
          // Handle the specific error
          dispatch(slice.actions.hasError(error));
          console.log('Email already in use');
          return { payload: { error: 'Email already in use' } };
        } 
          // Handle other errors
          dispatch(slice.actions.hasError(error));
          console.log('Generic error');
          return { payload: { error: 'Email already in use' } };
        
      }
      
    };
  }
  



  


 
  